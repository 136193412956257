import React            from 'react';

const SingleAmbassadorHeader = (props) => {
  const ambassador = props.ambassador,
        name       = ambassador.name,
        mandate    = ambassador.mandate,
        links      = ambassador.links ? JSON.parse(ambassador.links) : [];

  return (
    <div className='container bg-blue'>
      <ol className='numbered-list'>
        <li className='ambassador'>
          <header>

            <p className='index'></p>
            <p className='name title-xl uc'><span className='width-m'>{ name }</span></p>
            <div className='info text-s'>

              <p>Mandate:</p>
              <span>{ mandate }</span>

              <p className='mt-1'>Links:</p>
              {links.map((link, index) => {
                let text = link.text;
                let url  = link.link;

                return (
                  <p key={ index }>
                    <a href={ url }
                       target='_blank'
                       rel='noopener noreferrer'>
                      { text }
                    </a>
                  </p>
                )
              })}

            </div>
          </header>
        </li>
      </ol>
    </div>
  )
}

export default SingleAmbassadorHeader;
