import React, {useState, useEffect} from 'react';
import NavItem from './NavItem';

const MainNavigation = (props) => {
  let currentPage = props.location.pathname;
  let className   = (currentPage === '/') ? 'main-navigation open' : 'main-navigation';

  let org = window.location.origin || 'http://www.embassyofinternet.org';
  if (org.includes('localhost')) { org = 'http://www.embassyofinternet.org' }

  const [error, setError]       = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems]       = useState([]);

  useEffect(() => {
    fetch(org + "/content/home.json")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setItems(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  // Error state
  if (error) {
    return <div>Error: {error.message}</div>;

  // Loading state
  } else if (!isLoaded) {
    return <div>Loading...</div>;

  // Render
  } else {
    return (
      <nav className={ className }>
        <ol>
          {items.map((data, index) => {
            const action = data;

            return (
              <NavItem
                action={ action }
                currentPage={ currentPage }
                key={ index }
              />
            )
          })}
        </ol>
      </nav>
    );
  }
}

export default MainNavigation;
