import React        from 'react';
import { Link }     from 'react-router-dom';

const AmbassadorsList = (props) => {
  const ambassadors = props.ambassadors || [];

  return (

    <ol className='ambassadors-list numbered-list'>
      {Object.keys(ambassadors).map((key, index) => {
        let el      = ambassadors[key];
        let number  = el.number;
        let ongoing = el.state === 'open' ? <span className="current"></span> : null;
        let path    = '/ambassadors/'+ number;

        return (
          <li className='ambassador' key={ index }>
            <Link to={ path }>
              <header>
                <p className='title-m index'>{ number }</p>
                <p className='title-l uc'>{ el.name }</p>
                { ongoing }
              </header>
            </Link>
          </li>
        )

      })}
    </ol>

  )
}

export default AmbassadorsList;
