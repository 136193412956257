import React    from 'react';
import { Link } from 'react-router-dom';

const Cover = (props) => {
  const currentPage   = props.location.pathname;
  let   isSlided      = (currentPage !== '/') ? "cover slided" : "cover current";

  return (
    <section className={ isSlided }>
      <Link to='/'>

        <div className="block block-top">
          <h1>Embassy of Internet</h1>
        </div>

        <div className="block block-bottom">
          <div className="gradient"></div>
        </div>

      </Link>
    </section> 
  );

}

export default Cover;
