import React from 'react';

const ContentType = (props) => {
  const type      = props.el.type,
        className = props.el.class,
        url       = props.el.url,
        src       = props.el.src,
        text      = props.el.text;
  let   element;

  switch (type) {
    case 'link':
      element = 
        <p className={ className }>
          <a href={ url } target='_blank' rel='noopener noreferrer'>
            { text }
          </a>
        </p>
      break;

    case 'image':
      element = 
        <div className={ className }>
          <img src={ src } alt="" />
        </div>
      break;

    default:
      element = 
        <p className={ className }>
          { text }
        </p>
      break;
  }

  return element
}

export default ContentType;
