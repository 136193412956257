import React    from 'react';
import { Link } from 'react-router-dom';

const NavItem = (props) => {
  const currentPage = props.currentPage,
        action      = props.action,
        number      = action.number,
        linkTo      = '/'+ action.linkTo,
        title       = action.title,
        description = action.description,
        status      = action.status,
        date        = action.date,
        isActive    = currentPage.includes(action.linkTo);

  let className   = (isActive) ? 'active bg-' + action.bg : null;
  
  return (
    <li className={ className }>
      <Link to={ linkTo }>
        <p className='index title-m'>{ number }</p>
        <h2 className='title title-m uc'>{ title }</h2>
        <p className='description title-xs'><span>{ description }</span></p>
        <p className='status text-s'>{ status }</p>
        <p className='date text-s'>{ date }</p>
      </Link>
    </li>
  );
}

export default NavItem;
