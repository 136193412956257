import React, {useState, useEffect} from 'react';
import SingleAmbassadorHeader from './SingleAmbassadorHeader';
import Meta                   from '../../components/Meta';
import Content                from '../../components/Content';
import ContentHtml            from '../../components/ContentHtml';

const SingleAmbassador = (props) => {
  let origin      = window.location.origin || 'http://www.embassyofinternet.org';
  if (origin === 'http://localhost:3000') origin = 'http://www.embassyofinternet.org';

  const [error, setError]           = useState(null);
  const [isLoaded, setIsLoaded]     = useState(false);
  const [ambassador, setAmbassador] = useState([]);
  const currentId               = props.match.params.id;

  useEffect(() => {
    fetch(origin + "/content/ambassadors/"+ currentId +".json")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setAmbassador(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  // Error state
  if (error) {
    return <div>Error: {error.message}</div>;

  // Loading state
  } else if (!isLoaded) {
    return <div>Loading...</div>;

  // Render
  } else {
    const contentBlocks = ambassador.content ? JSON.parse(ambassador.content) : [];
    const title       = ambassador.title;
    const description = ambassador.description;
    const html        = ambassador.contentHtml;

    return (
      <main id='single-ambassador'>

        <Meta
          pathname={ props.history.location.pathname }
          page={ ambassador } />

        <SingleAmbassadorHeader
          ambassador={ ambassador } />

        <div className='container bg-grey pt-0'>
          <Content
            content={ contentBlocks }
            contentClass='content width-l' />

          <div className='intro width-l'>
            <h1 className='title-l col-green mt-3'>
            { title }
            </h1>

            <p className='text-l mt-3'>
            { description }
            </p>
          </div>

          <ContentHtml
            html={ html }
            contentClass='content width-l' />
        </div>

      </main>
    )
  }
}

export default SingleAmbassador;
