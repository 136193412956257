import firebase from 'firebase/app'
import 'firebase/database';
var config = {
  apiKey: "AIzaSyCrSqOiKgsazhGM3ELpw0Nk-tGz_UIUfyk",
  authDomain: "embassy-of-internet.firebaseapp.com",
  databaseURL: "https://embassy-of-internet.firebaseio.com",
  projectId: "embassy-of-internet",
  storageBucket: "embassy-of-internet.appspot.com",
  messagingSenderId: "1026482024913"
};
var fire = firebase.initializeApp(config);
export default fire;
