import React from 'react';

const Answers = (props) => {
  let answers     = props.text;
  let answersList = <p className="no-answers">No answers yet</p>;

  if (answers) {
    answersList = (
      <ul className="answers">
        {Object.keys(answers).map((key, index) => {
          let time = answers[key].time;
          let text = answers[key].text;
          return (
            <li className="single-answer text-s" key={ index }>
              <p className="time"> { time }</p>
              <p className="text col-green">{ text } </p>
            </li>
          )}
        )}
      </ul>
    )
  }

  return (
    <div>
      { answersList } 
    </div>
  )
}

export default Answers;
