import React, {useState, useEffect} from 'react';
import Meta    from '../../components/Meta';

const Home = (props) => {

  let org = window.location.origin || 'http://www.embassyofinternet.org';
  if (org.includes('localhost')) { org = 'http://www.embassyofinternet.org' }

  const [error, setError]       = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setContent]      = useState([]);

  useEffect(() => {
    fetch(org + "/content/mission.json")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setContent(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  // Error state
  if (error) {
    return <div>Error: {error.message}</div>;

  // Loading state
  } else if (!isLoaded) {
    return <div>Loading...</div>;

  // Render
  } else {
    return (
      <Meta
        pathname={ props.history.location.pathname }
        page={ page } />
    )
  }
}

export default Home;
