const meta = {
  "/home": {
    "title": "Home",
    "description": "A speculative experiment about the future of Internet and of our society",
    "image": "http://www.embassyofinternet.org/content/media/pages/home/1815032484-1598717589/embassy-flag.png",
  },

  "/mission": {
    "title": "Mission",
    "description": "A speculative experiment about the future of Internet and of our society",
    "image": "http://www.embassyofinternet.org/content/media/pages/home/1815032484-1598717589/embassy-flag.png",
  },

  "/questions": {
    "title": "Questions",
    "description": "Every week the Embassy will propose two open questions to the people of Internet",
    "image": "http://www.embassyofinternet.org/content/media/pages/home/1815032484-1598717589/embassy-flag.png",
  },

  "/open-call": {
    "title": "Open call",
    "description": "The Embassy of Internet is calling for ambassadors",
    "image": "http://www.embassyofinternet.org/content/media/pages/open-call/3365982333-1598717589/embassy-open_call.png",
  },

  "/ambassadors": {
    "title": "Ambassadors",
    "description": "Last Ambassador: Lara Chapman",
    "image": "http://www.embassyofinternet.org/content/media/pages/home/1815032484-1598717589/embassy-flag.png",
  },

  "/ambassadors/06": {
    "title": "Weird Web",
    "description": "During the mandate, Lara will explore the intersections between ironic and informative usage of Internet. She will conduct a series of interviews and create a digital archive of 'Weird Web' examples.",
    "image": "http://www.embassyofinternet.org/content/media/pages/ambassadors/06/1447369463-1598723643/embassy-ambassador_06.png",
  },

  "/ambassadors/05": {
    "title": "JennyCam Live",
    "description": "In her mandate, Katharine Wimett completed her project JenniCam, a web-based installation that reflects on the commodification of womenu2019s body in the web.",
    "image": "http://www.embassyofinternet.org/content/media/pages/ambassadors/05/300245031-1598717589/embassy-ambassador_05.png",
  },

  "/ambassadors/04": {
    "title": "Showing and hiding",
    "description": "During the mandate, Susanne Janssen continued her research on the relation between software and user-interface, elaborating an experimental website which reflects on being a mirror and window.",
    "image": "http://www.embassyofinternet.org/content/media/pages/ambassadors/04/746932631-1598717589/embassy-ambassador_04.png",
  },

  "/ambassadors/03": {
    "title": "Ideas for an anti-browser",
    "description": "During the mandate, Norma reflected and developed a concept on the neutrality and usability of the most common Internet access point, the browser.",
    "image": "http://www.embassyofinternet.org/content/media/pages/ambassadors/03/2661651847-1598717589/embassy-ambassador_03.png",
  },

  "/ambassadors/02": {
    "title": "INT.ERNET",
    "description": "As an Ambassadress of Internet, Katharina Drasdo will connect physical and digital realms through the creation of both material and virtual objects to promote the freedom of the Internet.",
    "image": "http://www.embassyofinternet.org/content/media/pages/ambassadors/02/2747644983-1598717589/embassy-ambassador_02.png",
  },

  "/ambassadors/01": {
    "title": "23 Years in Cyberspace",
    "description": "In his period as Ambassador of Internet, James Wrigley conducted a series of interviews to investigate the issues that concern the web today and possible future scenarios.",
    "image": "http://www.embassyofinternet.org/content/media/pages/ambassadors/01/3831875303-1598717589/embassy-ambassador_01.png",
  },

  "/error": {
    "title": "Error",
    "description": "",
    "image": "http://www.embassyofinternet.org/content/media/pages/home/1815032484-1598717589/embassy-flag.png",
  },

  "/meta": {
    "title": "meta",
    "description": "",
    "image": "http://www.embassyofinternet.org/content/media/pages/home/1815032484-1598717589/embassy-flag.png",
  }

};

export default meta;