import React        from 'react';
import MetaTags     from 'react-meta-tags';
import metaContent  from './metaContent';
 
const Meta = (props) => {
  const pathname    = props.pathname;

  // Get current page meta from meta JSON file
  const current     = metaContent[pathname] ? metaContent[pathname] : metaContent['/home'];
  const title       = current.title;
  const description = current.description;
  const image       = current.image;

  return (
    <MetaTags>
      <title>Embassy of Internet</title>

      <meta property="og:url"                content={"http://www.embassyofinternet.org"+ pathname } />
      <meta property="og:type"               content="website" />
      <meta property="og:title"              content={"Embassy of Internet · "+ title } />
      <meta property="og:description"        content={ description } />
      <meta property="og:image"              content={ image } />
      
      <meta name="twitter:card"              content="summary_large_image" />
      <meta name="twitter:site"              content="@internetembassy" />
      <meta name="twitter:title"             content={"Embassy of Internet · "+ title } />
      <meta name="twitter:description"       content={ description } />
      <meta name="twitter:creator"           content="@internetembassy" />
      <meta name="twitter:image"             content={ image } />
      <meta name="twitter:domain"            content="embassyofinternet.org" />

    </MetaTags>
  )
}

export default Meta;