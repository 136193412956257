import React                      from 'react';
import { Route, BrowserRouter }   from 'react-router-dom';
import Cover                      from './components/Cover';
import MainNavigation             from './components/main-navigation/MainNavigation';
import Home                       from './single-pages/00-home/Home';
import Mission                    from './single-pages/01-mission/Mission';
import Questions                  from './single-pages/02-questions/Questions';
import OpenCall                   from './single-pages/03-open-call/OpenCall';
import Ambassadors                from './single-pages/04-ambassadors/Ambassadors';
import SingleAmbassador           from './single-pages/04-ambassadors/SingleAmbassador';
import './css/1811-PVHUCN.css';
import './css/main.scss';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Route path='*'                   component={ Cover }/>
        <Route path='*'                   component={ MainNavigation }/>
        <Route exact path='/'             component={ Home }/>
        <Route path='/mission'            component={ Mission }/>
        <Route path='/questions'          component={ Questions }/>
        <Route path='/open-call'          component={ OpenCall }/>
        <Route exact path='/ambassadors/' component={ Ambassadors } />
        <Route path='/ambassadors/:id'    component={ SingleAmbassador } />
      </BrowserRouter>
    </div>
  )
}

export default App

