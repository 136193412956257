import React, { Component } from 'react';
import fire                 from '../../components/fire';

class ApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.name         = React.createRef();
    this.email        = React.createRef();
    this.proposal     = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      submissionError: false,
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    let application = {
      name:     this.name.current.value,
      email:    this.email.current.value,
      proposal: this.proposal.current.value
    }

    if (application.name !== '' && application.email !== '' && application.proposal !== '') {
      let ref = fire.database().ref('open-call');
      ref.push(application).then(() => {
        this.props.handleChange({isSubmitted: true});
        this.setState({submissionError: false})
      }, err => {
        this.props.handleChange({submissionError: true});
        this.setState({submissionError: true})
      })
    }
  }

  render() {
    let errorMessage = null;
    if (this.state.submissionError) { errorMessage = <div>Invalid data</div> }

    return (

      <form id="open-call-form">
        <input
          className="text-l"
          name="name"
          type="text"
          placeholder="Full name"
          ref={this.name} />

        <input
          className="text-l"
          name="email"
          type="email"
          placeholder="Email"
          ref={this.email} />

        <textarea
          className="text-l"
          name="proposal"
          placeholder="Proposal (max 500 characters)"
          maxLength="500"
          ref={this.proposal} />

        {errorMessage}

        <input
          className="text-l"
          name="submit"
          type="submit"
          id=""
          value="Submit"
          onClick={this.handleSubmit} />

      </form>

    );
  }

}
export default ApplicationForm;
