import React        from 'react';
import ContentType  from './ContentType';

const Content = (props) => {
  const className  = props.contentClass;
  const content    = props.content;

  if (content && content.length) {
    return (
      <div className={ className }>
        { content.map((el, index) => {
          return (
            <ContentType
              index={ index }
              el={ el }
              key={ index }
            />
          )
        })}
      </div>
    )
  } else {
    return null;
  }
}

export default Content;
