import React    from 'react';
import Answers  from './Answers';

const QandA = (props) => {
  const current = props.currentPage,
        QandA   = props.QandA;

  function handleClick(e, index) {
    let newQuestion = (props.currentPage !== index) ? index : -1;
    props.handleChange(newQuestion);
  }

  return (
    <ul className="numbered-list collapsible">

      {QandA.map((el, index) => {
        function addZero(i) { i++; if (i < 10) { i = "0" + i } return i }

        const questionId  = addZero(index),
              question    = el.question,
              answers     = el.answers,
              status      = el.active,
              isCurrent   = current === (index);

        let isActive      = isCurrent ? 'active' : null;

        return (
          <li
            className={isActive}
            key={index}>

            <header onClick={(e) => handleClick(e, index)}>
              <p className="index title-m">{ questionId }</p>
              <p className="title title-m width-m">{ question }</p>
            </header>

            <div className="content">
              <Answers
                id={ index }
                text={ answers }
                status={ status }
              />
            </div>

          </li>
        )
      })}

    </ul>
  )
}

export default QandA;
