import React, {useState, useEffect} from 'react';
import Content          from '../../components/Content';
import ContentHtml      from '../../components/ContentHtml';
import Meta             from '../../components/Meta';
import AmbassadorsList  from './AmbassadorsList';

const Ambassadors = (props) => {
  let org = window.location.origin || 'http://www.embassyofinternet.org';
  if (org.includes('localhost')) { org = 'http://www.embassyofinternet.org' }

  const [error, setError]       = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setContent]      = useState([]);

  useEffect(() => {
    fetch(org + "/content/ambassadors.json")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setContent(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  // Error state
  if (error) {
    return <div>Error: {error.message}</div>;

  // Loading state
  } else if (!isLoaded) {
    return <div>Loading...</div>;

  // Render
  } else {
    const contentBlocks = page.content ? JSON.parse(page.content) : [];
    const ambassadors = page.children;
    const html = page.contentHtml;

    return (
      <main
        id='ambassadors'
        className='container bg-blue full-height'>

        <Meta
          pathname={ props.history.location.pathname }
          page={ page } />

        <Content
          content={ contentBlocks }
          contentClass='content' />

        <ContentHtml
          html={ html }
          contentClass='content width-l' />

        <AmbassadorsList
          ambassadors={ ambassadors }/>

      </main>
    )
  }
}

export default Ambassadors;
