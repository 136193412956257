import React, { useState, useEffect }  from 'react';
import Meta                 from '../../components/Meta';
import QandA                from './QandA';
import QandAcontent         from './content/embassy-of-internet-export';

const Questions = (props) => {
  let org = window.location.origin || 'http://www.embassyofinternet.org';
  if (org.includes('localhost')) { org = 'http://www.embassyofinternet.org' }

  const [currentPage, setCurrentPage] = useState(-1);
  const [error, setError]       = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setContent]   = useState([]);

  useEffect(() => {
    fetch(org + "/content/questions.json")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setContent(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  function handlePageChange(val) {
    setCurrentPage(val)
  }

  // Error state
  if (error) {
    return <div>Error: {error.message}</div>;

  // Loading state
  } else if (!isLoaded) {
    return <div>Loading...</div>;

  // Render
  } else {
    return (
      <div
        className="container">

        <Meta
          pathname={ props.history.location.pathname }
          page={ page } />

        <QandA
          QandA={ QandAcontent }
          currentPage={ currentPage }
          handleChange={ handlePageChange }
        />

      </div>
    )
  }
}

export default Questions;
