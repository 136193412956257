import React, { useState, useEffect } from 'react';
import ContentHtml          from '../../components/ContentHtml';
import Meta                 from '../../components/Meta';
import ApplicationForm      from './ApplicationForm';

const OpenCall = (props) => {
  let org = window.location.origin || 'http://www.embassyofinternet.org';
  if (org.includes('localhost')) { org = 'http://www.embassyofinternet.org' }

  const [isSubmitted, submit]   = useState(false);
  const [error, setError]       = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setContent]      = useState([]);

  useEffect(() => {
    fetch(org + "/content/open-call.json")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setContent(data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  let applicationForm = (!isSubmitted) ?
    <ApplicationForm handleChange={ handleStatusChange } /> :
    <p className='answered text-l mb-4'>Thanks for applying! We will contact you after reviewing all applications</p>

  function handleStatusChange(newStatus) {
    submit(newStatus);
  }

  // Error state
  if (error) {
    return <div>Error: {error.message}</div>;

  // Loading state
  } else if (!isLoaded) {
    return <div>Loading...</div>;

  // Render
  } else {
    const html = page.contentHtml;

    return (
      <main
        id='open-call'
        className="container bg-grey">

        <Meta
          pathname={ props.history.location.pathname }
          page={ page } />

        <ContentHtml
          html={ html }
          contentClass='content width-l' />

        { applicationForm }

      </main>
    )
  }
}

export default OpenCall;
